<template>
    <div class="nodewrappar"
        :class="[showCon ? 'animate__animated animate__zoomIn' : 'animate__animated animate__zoomOut']">
        <div class="stardetails-bg flex">
         
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showCon: true,
            act: 0,
            iconInfo: {},
            coinList: []
        }
    },
    methods: {
        handlerClose() {
            this.showCon = false;
            setTimeout(() => {
                this.$emit('handlerClose')
            }, 500)
        }
    }
}
</script>

<style lang="less" scoped>
.nodewrappar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: url('../public/images/new/infobg.png') top no-repeat;
    background-size: 100% 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0px; //修改滚动条宽度
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #666666;
    }

    .stardetails-bg {
   
    }
}

.el-dropdown {
    border: 0 !important;
}

.el-dropdown-link {
    width: 345px;
    padding: 12px 0;
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2) !important;

    .icon-name {
        position: relative;
        padding: 0 16px;
        font-size: 16px;
        color: #fff;
        line-height: 24px;
        cursor: pointer;

        img {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            position: absolute;
            top: 0;
            right: 34px;
            font-size: 16px;
            color: #fff;
            line-height: 24px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 9px;
            right: 16px;
            width: 8px;
            height: 6px;
            background: url('../public/images/new/loginicon1.png') center no-repeat;
            background-size: 100% 100%;
        }
    }
}

.coin-info {

    position: relative;
    display: flex;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    cursor: pointer;
    width: 100%;

    img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }

    p {
        position: absolute;
        top: 0;
        right: 16px;
        font-size: 16px;
        color: #BBBBBB;
    }
}</style>