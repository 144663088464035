<template>
    <div class="land" :class="{hideCon:showInfo}">
        <div class="land-con">
            <div class="land-name animate__animated animate__fadeInUp">
                <div>{{ $t('land.desc1') }}</div>
                <div>{{ $t('land.desc2') }}</div>
            </div>
            <div class="land-nav flex animate__animated animate__fadeInUp">
                <div class="nav-list">
                    <div class="nav flex">
                        <div v-for="(item, index) in 3" :key="item.id" :class="{ act: act == index }">石器时代234</div>
                    </div>
                </div>
                <div class="nav-search flex">
                    <div class="search">
                        <input type="text" :placeholder="$t('land.desc3')" />
                    </div>
                    <div class="search-btn"></div>
                </div>
            </div>
            <div class="land-list flex animate__animated animate__fadeInUp animate__delay-.4s">
                <div class="list-info flex" v-for="item in 6" :key="item.id">
                    <img src="" alt="" class="img" />
                    <div class="info">
                        <div class="info-name">星球命名的名字</div>
                        <div class="info-copy flex">
                            {{ $t('land.desc4') }} <span>0xEA..........8BA1</span> <img src="../../public/images/new/copy.png" alt="" />
                        </div>
                        <div class="info-stack flex">
                            <div class="stack">
                                <div>23-48</div>
                                <p>{{ $t('node.desc19') }}</p>
                            </div>
                            <div class="stack">
                                <div>23-48</div>
                                <p>{{ $t('land.desc5') }}</p>
                            </div>
                            <div class="stack">
                                <div>23-48</div>
                                <p>{{ $t('land.desc6') }}</p>
                            </div>
                            <div class="stack">
                                <div>23-48</div>
                                <p>{{ $t('land.desc7') }}</p>
                            </div>
                        </div>
                        <div class="info-btn" @click="handlerInfo">{{ $t('land.desc8') }}</div>
                    </div>
                </div>
            </div>
            <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="6" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent"> </el-pagination>
                </div>
        </div>
        <landInfo v-if="showInfo" @handlerClose="handlerClose" />
    </div>
</template>

<script>
import landInfo from '../../componets/landinfo.vue'
export default {
    components:{
        landInfo
    },
    data() {
        return {
            act: 0,
            totals:400,
            page:1,
            showInfo:false
        }
    },
    methods:{
        handlerCurrent(val) {

        },
        handlerClose() {
            this.showInfo=false;
        },
        handlerInfo() {
            this.showInfo=true;
        }
    }
}
</script>

<style lang="less" scoped>
.land {
    width: 100%;
    padding: 136px 0 80px;
    background: url('../../public/images/new/landbg.png') center no-repeat;
    background-size: 100% 100%;

    .land-con {
        width: 1240px;
        margin: 0 auto;

        .land-name {
            font-size: 20px;
            color: #fff;
            line-height: 28px;
        }

        .land-nav {
            margin: 28px 0 32px;
            border-bottom: 1px solid rgba(238, 238, 238, 0.2);

            .nav-search {
                flex: 0 0 325px;

                .search {
                    flex: 0 0 280px;
                    height: 36px;
                    background: url('../../public/images/new/searchng.png') center no-repeat;
                    background-size: 100% 100%;

                    input {
                        width: 256px;
                        margin-left: 12px;
                        height: 34px;
                        font-size: 14px;
                        color: #fff;

                        &::placeholder {
                            color: rgba(255, 255, 255, .4);
                        }
                    }
                }

                .search-btn {
                    flex: 0 0 46px;
                    height: 36px;
                    background: url('../../public/images/new/searchicon.png') center no-repeat;
                    background-size: 100% 100%;
                    cursor: pointer;
                }
            }

            .nav-list {
                flex: 1;
                width: max-content;
                margin-right: 20px;
                overflow-y: scroll;
                padding-bottom: 12px;

                &::-webkit-scrollbar {
                    background-color: rgba(238, 238, 238, 0) !important;
                    width: 0;
                    height: 3px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.2) !important;
                    border-radius: 5px;
                    height: 2px !important;
                }



                /* 改变滚动条的轨道宽度和颜色 */
                &::-webkit-scrollbar-track {
                    background-color: rgba(238, 238, 238, 0) !important;
                    border-radius: 1px;
                }

                .nav {
                    width: max-content;

                    div {
                        position: relative;
                        margin-right: 32px;
                        font-size: 14px;
                        color: #BBBBBB;
                        cursor: pointer;
                        line-height: 34px;

                        &:hover {
                            color: #fff;

                            &::after {
                                content: '';
                                position: absolute;
                                bottom: -12px;
                                left: 0;
                                width: 100%;
                                height: 2px;
                                background: #D2FB71;
                                border-radius: 5px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .act {

                        color: #fff;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -12px;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background: #D2FB71;
                            border-radius: 5px;
                        }
                    }


                }

            }
        }
        .land-list{
            margin-bottom: 40px;
            flex-wrap: wrap;
            .list-info{
                flex: 0 0 613px;
                height: 188px;
                overflow: hidden;
                margin:14px 14px 0 0;
                border-radius: 16px;
                background: #181818;
                border: 2px solid #181818;
                box-sizing: border-box;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    border: 2px solid #D2FB71;
                    transition: .5s;
                    .img{
                        margin-top: -21px;
                        transition: .5s;
                    }
                    .info{
                        margin-top: -55px;
                        transition: .5s;
                        .info-btn{
                            display: block;
                        }
                    }
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
                &:nth-child(-n + 2){
                    margin-top: 0;
                }
                .img{
                    flex: 0 0 140px;
                    width: 140px;
                    height: 140px;
                    margin: 24px 32px 0 24px;
                    transition: .5s;
                }
                .info{
                    flex: 1;
                    padding-top: 36px;
                    overflow: hidden;
                    transition: .5s;
                    .info-name{
                        font-size: 24px;
                        color: #fff;
                        line-height: 28px;
                    }
                    .info-copy{
                        margin: 12px 0 24px;
                        font-size: 14px;
                        color: #A8A8A8;
                        line-height: 14px;
                        span{
                            color: #fff;
                        }
                        img{
                            width: 12px;
                            height: 12px;
                            margin: 1px 0 0 8px;
                            cursor: pointer;
                        }
                    }
                    .info-stack{
                        flex-wrap: wrap;
                        .stack{
                            flex: 0 0 25%;
                            font-size: 13px;
                            color: #A8A8A8;
                            line-height: 13px;
                            div{
                                margin-bottom: 8px;
                                font-size: 16px;
                                color: #FFFFFF;
                                line-height: 18px;
                            }
                        }
                    }
                    .info-btn{
                        width: 194px;
                        height: 38px;
                        margin-top: 20px;
                        background: url('../../public/images/new/btnbg1.png') center no-repeat;
                        background-size: 100% 100%;
                        text-align: center;
                        line-height: 38px;
                        font-size: 14px;
                        color: #000000;
                        font-weight: 600;
                        display: none;
                    }
                }
            }
        }
    }
}
.hideCon{
    height: 1vh;
    overflow: hidden;
}
.planet-page {
        padding: 0;

        ::v-deep .el-pagination {
            button {
                background-color: rgba(255, 255, 255, 0);
                color: #666666;
                font-size: 14px;
            }

            .el-pager {
                li {
                    background: rgba(255, 255, 255, 0);
                    font-size: 14px;
                    color: #666666;
                }

                .is-active {
                    background: #C9FA5B !important;
                    border-radius: 4px !important;
                    color: #000000 !important;
                }
            }

        }
    }
</style>